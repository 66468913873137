interface TitleOpts {
  replace?: boolean
  t?: boolean
}

const defaultOpts = {
  replace: false,
  t: false
}

const useTitle = (title: string, opts: TitleOpts = defaultOpts) => {
  const { t } = useI18n()

  const defaultTitle = 'PingZapper'

  let append = opts.t ? t(title) : title
  let finalTitle = defaultTitle

  if (opts.replace) {
    finalTitle = append
  } else {
    finalTitle += ` - ${append}`
  }

  useHead({ title: finalTitle })
}

const useDescription = (description: string) => {
  useHead({meta: [{ name: 'description', content: description }]})
}

export { useTitle, useDescription }
export type { TitleOpts }
